<template>
    <div v-if="mod">
        <context-title 
            :title="pageTitle" 
            :passedActions="titleBarActions" />

        <key-val-line prop="Enabled">
            <toggle 
                v-model="mod.enabled" 
                :slider="true"/>
        </key-val-line>

        <key-val-line prop="Item ID">
            <form-input v-model="mod.sku" />
        </key-val-line>

        <key-val-line prop="Path">
            <form-input v-model="mod.path"/>
        </key-val-line>

        <key-val-line prop="Tax (Default)">
            <div class="col-12">
                <toggle 
                    v-model="mod.defaultTax" 
                    label="Tax (Default)" 
                    class="d-inline-block block-xs--md block-xs--xs-right" />Default ({{ mod.taxRate | parseTaxRate }} %)
                <br >
                <span :class="{label: true, disabled: mod.defaultTax}">
                    Tax&nbsp;(Custom)
                </span>
                <div 
                    v-if="mod.taxes" 
                    class="group-inputs">
                    <template v-for="tax in mod.taxes">
                        <form-input 
                            :value="tax.Amount | parseTaxRate" 
                            :disabled="mod.defaultTax" 
                            :label="tax.Description" 
                            type="number" 
                            class="d-inline-block block-xs--xs-right block-xs--xs-bottom sub-label" 
                            @input="tax.Amount = arguments[0] / 100" />%<br >
                    </template>
                </div>
            </div>
        </key-val-line>

        <key-val-line prop="Mod Variations">
            Yield variation list
        </key-val-line>

        <key-val-line prop="Platform">
            <div class="col-7 col-md-5">
                <div class="row no-gutters">
                    <div class="col">In-store only</div>
                    <toggle 
                        v-model="mod.storeOnly" 
                        class="col" />
                </div>

                <div 
                    v-for="platform in mod.platforms" 
                    class="row no-gutters">
                    <div class="col">{{ platform.display }}</div>
                    <toggle 
                        v-model="platform.active" 
                        :disabled="mod.storeOnly" 
                        class="col" />
                </div>
            </div>
        </key-val-line>

        <key-val-line prop="Options">
            <div class="col-7 col-md-5">
                <div 
                    v-for="option in mod.options" 
                    class="row no-gutters">
                    <div class="col">{{ option.display }}</div>
                    <toggle 
                        v-model="option.active" 
                        class="col" />
                </div>
            </div>
        </key-val-line>

        <key-val-line prop="Time">
            <div class="col-7 col-md-5">
                <div class="row no-gutters">
                    <div class="col">Start</div>
                    <form-input 
                        v-model="mod.startTime" 
                        class="col" />
                </div>

                <div class="row no-gutters">
                    <div class="col">End</div>
                    <form-input 
                        v-model="mod.endTime" 
                        class="col" />
                </div>
            </div>
        </key-val-line>

        <key-val-line prop="Attributes">
            <div class="col-7 col-md-5">
                <div 
                    v-for="attribute in mod.attributes" 
                    class="row no-gutters">
                    <div class="col">{{ attribute.display }}</div>
                    <toggle 
                        v-model="attribute.active" 
                        class="col" />
                </div>
            </div>
        </key-val-line>

        <key-val-line prop="Images">
            <image-upload v-model="mod.images" />
        </key-val-line>

        <line-item 
            v-if="mod.id" 
            class="col-12 height-xs--12">
            <div class="row no-gutters height-xs--inherit align-items-center">
                <text-button
                    class="block-xs--sm-left"
                    type="delete"
                    @click.native="deleteModalActive = true">Delete mod</text-button>
            </div>
        </line-item>

        <editing-modal 
            v-if="deleteModalActive" 
            size="sm">
            <alert-dialog
                :onExit="() => deleteModalActive = false"
                :actions="deleteActions"
                title="Delete Mod?"
            >
                This will delete {{ mod.title }}. Are you sure? You can also disable it instead.
            </alert-dialog>
        </editing-modal>

        <unload-confirm
            :onExit="handleLeaveModalExit"
            :leaveAction="leaveConfirmationAction"
            :actions="leaveActions" />
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { parseTaxRate, spreadItems } from 'helpers'
import { pageUnloadController } from '../mixins.js'
import contextTitle from 'components/context_title.vue'
import keyValLine from 'components/key_val_line.vue'
import modGroup from 'components/mods/mod_group.vue'
import toggle from 'components/cleverly/Toggle.vue'
import formInput from 'components/cleverly/FormInput.vue'
import imageUpload from 'components/image_upload.vue'
import lineItem from 'components/line_item.vue'
import textButton from 'components/text_button.vue'
import editingModal from 'components/editing_modal.vue'
import alertDialog from 'components/alert_dialog.vue'

export default {
    name: 'StoreMod',
    mixins: [pageUnloadController],
    data() {
        return {
            mod: false,
            baseMod: {
                title: '',
                enabled: true,
                sortOrder: 1,
                sku: '',
                path: '',
                defaultTax: true,
                taxRate: 0,
                taxes: [
                    {Amount: 0, Description: 'State'},
                    {Amount: 0, Description: 'Local'},
                    {Amount: 0, Description: 'Other'},
                ],
                storeOnly: false,
                platforms: [
                    {type: 'mobile', display: 'Mobile', active: false},
                    {type: 'kiosk', display: 'Kiosk', active: false},
                    {type: 'tableTop', display: 'Table Top', active: false},
                    {type: 'other', display: 'Other', active: false},
                ],
                options: [
                    {type: 'grabGo', display: 'Grab and go', active: false},
                    {type: 'featured', display: 'Featured Item', active: false},
                    {type: 'employeeOnly', display: 'Employee Only', active: false},
                ],
                attributes: [
                    {type: 'fairTrade', display: 'Fair Trade', active: false},
                    {type: 'local', display: 'Local', active: false},
                    {type: 'organic', display: 'Organic', active: false},
                ],
                startTime: '2:00 PM',
                endTime: '7:00 PM',
                images: [],
            },
            deleteModalActive: false,
            titleBarActions: [
                {
                    type: 'cancel',
                    display: 'Cancel',
                    run: () => {
                        let routeName = 'store-mod-index'

                        if (this.mod.id) {
                            routeName = 'store-mod-detail'
                        }

                        this.$router.push({name: routeName})
                    },
                },
                {
                    type: 'proceed',
                    display: 'Save',
                    run: () => {
                        if (!this.mod.id) {
                            this.$router.push({name: 'store-mod-index'})
                        }

                        this.saveMod(this.mod)
                    },
                },
            ],
            deleteActions: [
                {
                    type: 'disable',
                    display: 'Disable',
                    run: this.disableSelf,
                },
                {
                    type: 'remove-red',
                    display: 'Delete',
                    run: this.deleteSelf,
                },
            ],
        }
    },
    computed: {
        pageTitle() {
            return [
                {to: {name: 'store-mod-index'}, label: 'Mods'},
                (this.mod.title || 'Add New Mod'),
            ]
        },
        ...mapGetters([
            'mods',
        ]),
    },
    methods: {
        disableSelf() {
            this.deleteModalActive = false
            this.mod.enabled = false
            this.toast('This mod has been disabled.')
        },
        deleteSelf() {
            this.deleteModalActive = false
            this.deleteMod(this.mod.id)
            this.$router.push({name: 'store-mod-index',})
            this.toast('Mod deleted.')
        },
        ...mapActions(['saveMod', 'deleteMod']),
    },
    filters: {
        parseTaxRate,
        spreadItems,
    },
    components: {
        contextTitle,
        keyValLine,
        modGroup,
        toggle,
        formInput,
        imageUpload,
        lineItem,
        textButton,
        editingModal,
        alertDialog,
    },
    watch: {
        mod: {
            handler() {
                this.handleChange()
            },
            deep: true,
        },
    },
    mounted() {
        const id = this.$route.params.mod
        let mod = this.baseMod

        if (id) {
            mod = this.mods().filter(mod => mod.id === id)[0]
        }
        this.mod = mod
    },
}
</script>
