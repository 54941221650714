var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.mod
    ? _c(
        "div",
        [
          _c("context-title", {
            attrs: { title: _vm.pageTitle, passedActions: _vm.titleBarActions },
          }),
          _c(
            "key-val-line",
            { attrs: { prop: "Enabled" } },
            [
              _c("toggle", {
                attrs: { slider: true },
                model: {
                  value: _vm.mod.enabled,
                  callback: function ($$v) {
                    _vm.$set(_vm.mod, "enabled", $$v)
                  },
                  expression: "mod.enabled",
                },
              }),
            ],
            1
          ),
          _c(
            "key-val-line",
            { attrs: { prop: "Item ID" } },
            [
              _c("form-input", {
                model: {
                  value: _vm.mod.sku,
                  callback: function ($$v) {
                    _vm.$set(_vm.mod, "sku", $$v)
                  },
                  expression: "mod.sku",
                },
              }),
            ],
            1
          ),
          _c(
            "key-val-line",
            { attrs: { prop: "Path" } },
            [
              _c("form-input", {
                model: {
                  value: _vm.mod.path,
                  callback: function ($$v) {
                    _vm.$set(_vm.mod, "path", $$v)
                  },
                  expression: "mod.path",
                },
              }),
            ],
            1
          ),
          _c("key-val-line", { attrs: { prop: "Tax (Default)" } }, [
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("toggle", {
                  staticClass: "d-inline-block block-xs--md block-xs--xs-right",
                  attrs: { label: "Tax (Default)" },
                  model: {
                    value: _vm.mod.defaultTax,
                    callback: function ($$v) {
                      _vm.$set(_vm.mod, "defaultTax", $$v)
                    },
                    expression: "mod.defaultTax",
                  },
                }),
                _vm._v(
                  "Default (" +
                    _vm._s(_vm._f("parseTaxRate")(_vm.mod.taxRate)) +
                    " %) "
                ),
                _c("br"),
                _c(
                  "span",
                  { class: { label: true, disabled: _vm.mod.defaultTax } },
                  [_vm._v(" Tax (Custom) ")]
                ),
                _vm.mod.taxes
                  ? _c(
                      "div",
                      { staticClass: "group-inputs" },
                      [
                        _vm._l(_vm.mod.taxes, function (tax) {
                          return [
                            _c("form-input", {
                              staticClass:
                                "d-inline-block block-xs--xs-right block-xs--xs-bottom sub-label",
                              attrs: {
                                value: _vm._f("parseTaxRate")(tax.Amount),
                                disabled: _vm.mod.defaultTax,
                                label: tax.Description,
                                type: "number",
                              },
                              on: {
                                input: function ($event) {
                                  tax.Amount = arguments[0] / 100
                                },
                              },
                            }),
                            _vm._v("%"),
                            _c("br"),
                          ]
                        }),
                      ],
                      2
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _c("key-val-line", { attrs: { prop: "Mod Variations" } }, [
            _vm._v(" Yield variation list "),
          ]),
          _c("key-val-line", { attrs: { prop: "Platform" } }, [
            _c(
              "div",
              { staticClass: "col-7 col-md-5" },
              [
                _c(
                  "div",
                  { staticClass: "row no-gutters" },
                  [
                    _c("div", { staticClass: "col" }, [
                      _vm._v("In-store only"),
                    ]),
                    _c("toggle", {
                      staticClass: "col",
                      model: {
                        value: _vm.mod.storeOnly,
                        callback: function ($$v) {
                          _vm.$set(_vm.mod, "storeOnly", $$v)
                        },
                        expression: "mod.storeOnly",
                      },
                    }),
                  ],
                  1
                ),
                _vm._l(_vm.mod.platforms, function (platform) {
                  return _c(
                    "div",
                    { staticClass: "row no-gutters" },
                    [
                      _c("div", { staticClass: "col" }, [
                        _vm._v(_vm._s(platform.display)),
                      ]),
                      _c("toggle", {
                        staticClass: "col",
                        attrs: { disabled: _vm.mod.storeOnly },
                        model: {
                          value: platform.active,
                          callback: function ($$v) {
                            _vm.$set(platform, "active", $$v)
                          },
                          expression: "platform.active",
                        },
                      }),
                    ],
                    1
                  )
                }),
              ],
              2
            ),
          ]),
          _c("key-val-line", { attrs: { prop: "Options" } }, [
            _c(
              "div",
              { staticClass: "col-7 col-md-5" },
              _vm._l(_vm.mod.options, function (option) {
                return _c(
                  "div",
                  { staticClass: "row no-gutters" },
                  [
                    _c("div", { staticClass: "col" }, [
                      _vm._v(_vm._s(option.display)),
                    ]),
                    _c("toggle", {
                      staticClass: "col",
                      model: {
                        value: option.active,
                        callback: function ($$v) {
                          _vm.$set(option, "active", $$v)
                        },
                        expression: "option.active",
                      },
                    }),
                  ],
                  1
                )
              }),
              0
            ),
          ]),
          _c("key-val-line", { attrs: { prop: "Time" } }, [
            _c("div", { staticClass: "col-7 col-md-5" }, [
              _c(
                "div",
                { staticClass: "row no-gutters" },
                [
                  _c("div", { staticClass: "col" }, [_vm._v("Start")]),
                  _c("form-input", {
                    staticClass: "col",
                    model: {
                      value: _vm.mod.startTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.mod, "startTime", $$v)
                      },
                      expression: "mod.startTime",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "row no-gutters" },
                [
                  _c("div", { staticClass: "col" }, [_vm._v("End")]),
                  _c("form-input", {
                    staticClass: "col",
                    model: {
                      value: _vm.mod.endTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.mod, "endTime", $$v)
                      },
                      expression: "mod.endTime",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c("key-val-line", { attrs: { prop: "Attributes" } }, [
            _c(
              "div",
              { staticClass: "col-7 col-md-5" },
              _vm._l(_vm.mod.attributes, function (attribute) {
                return _c(
                  "div",
                  { staticClass: "row no-gutters" },
                  [
                    _c("div", { staticClass: "col" }, [
                      _vm._v(_vm._s(attribute.display)),
                    ]),
                    _c("toggle", {
                      staticClass: "col",
                      model: {
                        value: attribute.active,
                        callback: function ($$v) {
                          _vm.$set(attribute, "active", $$v)
                        },
                        expression: "attribute.active",
                      },
                    }),
                  ],
                  1
                )
              }),
              0
            ),
          ]),
          _c(
            "key-val-line",
            { attrs: { prop: "Images" } },
            [
              _c("image-upload", {
                model: {
                  value: _vm.mod.images,
                  callback: function ($$v) {
                    _vm.$set(_vm.mod, "images", $$v)
                  },
                  expression: "mod.images",
                },
              }),
            ],
            1
          ),
          _vm.mod.id
            ? _c("line-item", { staticClass: "col-12 height-xs--12" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "row no-gutters height-xs--inherit align-items-center",
                  },
                  [
                    _c(
                      "text-button",
                      {
                        staticClass: "block-xs--sm-left",
                        attrs: { type: "delete" },
                        nativeOn: {
                          click: function ($event) {
                            _vm.deleteModalActive = true
                          },
                        },
                      },
                      [_vm._v("Delete mod")]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm.deleteModalActive
            ? _c(
                "editing-modal",
                { attrs: { size: "sm" } },
                [
                  _c(
                    "alert-dialog",
                    {
                      attrs: {
                        onExit: () => (_vm.deleteModalActive = false),
                        actions: _vm.deleteActions,
                        title: "Delete Mod?",
                      },
                    },
                    [
                      _vm._v(
                        " This will delete " +
                          _vm._s(_vm.mod.title) +
                          ". Are you sure? You can also disable it instead. "
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _c("unload-confirm", {
            attrs: {
              onExit: _vm.handleLeaveModalExit,
              leaveAction: _vm.leaveConfirmationAction,
              actions: _vm.leaveActions,
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }